import * as dateFns from 'date-fns'

/**
 * Check if a given date is DST in the US
 * - begins on the second Sunday in March
 * - ends on the first Sunday in November
 *
 * Keep in sync with https://github.com/finvizhq/charts/blob/master/app/utils.ts
 */
export function getIsDstInNy(date: Date) {
    const dayNumber = date.getDate()
    const monthIndex = date.getMonth()
    const dayOfWeek = date.getDay()
    const previousSunday = dayNumber - dayOfWeek
    if (monthIndex < 2 || monthIndex > 10) {
        return false
    }
    if (monthIndex > 2 && monthIndex < 10) {
        return true
    }
    return monthIndex === 2 ? previousSunday >= 8 : previousSunday <= 0
}

export function getDateInNY(date: Date) {
    const utcOffsetInHours = -5; // Standard UTC offset for NY
    const dateAsNY = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours() + utcOffsetInHours,
        date.getUTCMinutes(),
        date.getUTCSeconds()
    );

    const isDst = getIsDstInNy(dateAsNY);

    if (isDst) {
        dateAsNY.setHours(dateAsNY.getHours() + 1); // Add 1 hour for DST
    }

    // const options: Intl.DateTimeFormatOptions = {
    //     timeZone: 'America/New_York',
    //     timeZoneName: 'short',
    //     weekday: 'short',
    //     year: 'numeric',
    //     month: 'short',
    //     day: 'numeric',
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     second: 'numeric',
    //     hour12: false,
    // };

    // console.log(dateAsNY,new Intl.DateTimeFormat('en-US', options).format(dateAsNY));
    return dateAsNY;
}
export function getDate(seed?: string | number | Date | null) {
    var date = seed != null ? new Date(seed) : new Date()

    return getDateInNY(date)
}

function getWeekDay(date: Date) {
    return date.toLocaleDateString('en-US', { weekday: 'short' })
}

function getMonth(date: Date, uppercase = true) {
    const name = date.toLocaleDateString('en-US', { month: 'short' })
    return uppercase ? name.toUpperCase() : name
}

function getDay(date: Date) {
    return date.getDate().toString().padStart(2, '0')
}

function getTime(date: Date) {
    return date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
}

enum TimeFormat {
    long,
    short,
    dateOnly,
    timeOnly,
}

export type TimeFormatType = keyof typeof TimeFormat

export function getFormattedDateString(date: Date = getDate(), format: TimeFormatType = 'long') {
    switch (TimeFormat[format]) {
        case TimeFormat.long:
            return `${getWeekDay(date)} ${getMonth(date)} ${getDay(date)} ${date.getFullYear()} ${getTime(date)} ET`
        case TimeFormat.short:
            return `${getWeekDay(date)} ${getMonth(date)} ${getDay(date)} ${getTime(date)}`
        case TimeFormat.dateOnly:
            return `${getMonth(date, false)} ${getDay(date)}`
        case TimeFormat.timeOnly:
            return getTime(date)
    }
}

// export function formatDate(value: Date | unknown, inputOrOutputFormat: string, outputFormat?: string): string {
//     return dateFns.format(
//         dateFns.isDate(value) ? (value as Date) : dateFns.parse(`${value}`, inputOrOutputFormat, new Date()),
//         outputFormat ?? inputOrOutputFormat
//     )
// }

export function isPremarket(date: Date = getDate(), isPremium = true) {
    const dayOfWeek = date.getDay()
    const min = date.getMinutes()
    const hours = date.getHours()

    if (isPremium && dayOfWeek !== 0 && dayOfWeek !== 6 && (hours === 7 || hours === 8 || (hours === 9 && min < 30))) {
        return true
    }
    return false
}

export function isAftermarket(date: Date = getDate(), isPremium = true) {
    const dayOfWeek = date.getDay()
    const min = date.getMinutes()

    if (
        isPremium &&
        dayOfWeek !== 0 &&
        dayOfWeek !== 6 &&
        (date.getHours() === 16 || date.getHours() === 17 || (date.getHours() === 18 && min < 30))
    ) {
        return true
    }
    return false
}

/**
 * https://www.nyse.com/markets/hours-calendars
 */
const HOLIDAY_DATES = [
    {
        label: "New Year's Day",
        test: (day: number, month: number) => month === 1 && day === 1,
    },
    {
        label: 'Martin Luther King, Jr. Day',
        test: (day: number, month: number) => month === 1 && day === 15,
    },
    {
        label: 'Presidents Day',
        test: (day: number, month: number) => month === 2 && day === 19,
    },
    {
        label: 'Good Friday',
        test: (day: number, month: number) => month === 3 && day === 29,
    },
    {
        label: 'Memorial Day',
        test: (day: number, month: number) => month === 5 && day === 27,
    },
    {
        label: 'Juneteenth Holiday',
        test: (day: number, month: number) => month === 6 && day === 19,
    },
    {
        label: 'Early Close',
        test: (day: number, month: number, hours: number) => month === 7 && day === 3 && hours >= 13,
    },
    {
        label: 'Independence Day',
        test: (day: number, month: number) => month === 7 && day === 4,
    },
    {
        label: 'Labor Day',
        test: (day: number, month: number) => month === 9 && day === 2,
    },
    {
        label: 'Thanksgiving Day',
        test: (day: number, month: number) => month === 11 && day === 28,
    },
    {
        label: 'Day after Thanksgiving (closed from 1 PM)',
        test: (day: number, month: number, hours: number) => month === 11 && day === 29 && hours >= 13,
    },
    {
        label: 'Christmas Day',
        test: (day: number, month: number) => month === 12 && day === 25,
    },
]

export function getHoliday(date: Date = getDate()) {
    var day = date.getDate()
    var month = date.getMonth() + 1
    var hours = date.getHours()

    return HOLIDAY_DATES.find((holiday) => holiday.test(day, month, hours))
}

export function isHoliday(date: Date = getDate()) {
    return !!getHoliday(date)
}

export function isMarketOpen(date: Date = getDate(), isPremium = true) {
    if (isPremarket(date, isPremium) || isAftermarket(date, isPremium) || isHoliday(date)) {
        return false
    }

    const dayOfWeek = date.getDay()
    const hour = date.getHours()
    const minute = date.getMinutes()

    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6
    // Day starts at 9:30
    const dayStarted = hour === 9 ? minute >= 30 : hour >= 10
    // Ends at 16:00
    const dayEnded = hour >= 16

    return !isWeekend && dayStarted && !dayEnded
}

// duplicate from charts/app/utils/helpers.ts , will be refactored in next PR
export function getEarningsDateSuffix(date: Date) {
    const hours = date.getHours()

    if (hours <= 9) {
        return 'BMO'
    } else if (hours >= 16) {
        return 'AMC'
    }

    return ''
}
