import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

import { ThemeDetailsContext } from "../../context/ThemeContext";
import { useAuth } from "src/context/AuthContext";

// Menu Icons Dark Mode
import iconDashboardLight from "../../assets/images/menu-icons/icon-dashboard-light.svg";
import iconHotFireLight from "../../assets/images/menu-icons/icon-hot-fire-light.svg";
import iconNewsLight from "../../assets/images/menu-icons/icon-news-light.svg";
import iconLiveFlowLight from "../../assets/images/menu-icons/icon-bar-graph-light.svg";
import iconTickerLight from "../../assets/images/menu-icons/icon-ticket-light.svg";
import iconFilterLight from "../../assets/images/menu-icons/icon-filter-sliders-light.svg";
import iconAlertLight from "../../assets/images/menu-icons/icon-bell-light.svg";
import iconSettingLight from "../../assets/images/menu-icons/icon-cog-light.svg";
import iconLogoutLight from "../../assets/images/menu-icons/icon-logout-light.svg";
import iconDarkPoolLight from "../../assets/images/menu-icons/icon-trend-tag-light.svg";
import iconBookmarkLight from "../../assets/images/menu-icons/icon-start-light.svg";
import iconCalendarLight from "../../assets/images/menu-icons/icon-calendar-light.svg";
import iconEarningLight from "../../assets/images/menu-icons/icon-earning-light.svg";
import iconDividendLight from "../../assets/images/menu-icons/icon-dividend-light.svg";
import iconSenateLight from "../../assets/images/menu-icons/icon-senate-light.svg";


// Menu Icons Light Mode
import iconSenateDark from "../../assets/images/menu-icons/icon-senate-dark.svg";
import iconDividendDark from "../../assets/images/menu-icons/icon-dividend-dark.svg";
import iconDashboardPrimary from "../../assets/images/menu-icons/icon-dashboard-primary.svg";
import iconHotFirePrimary from "../../assets/images/menu-icons/icon-hot-fire-primary.svg";
import iconNewsPrimary from "../../assets/images/menu-icons/icon-news-primary.svg";
import iconCalendarPrimary from "../../assets/images/menu-icons/icon-calendar-dark.svg";
import iconEarningDark from "../../assets/images/menu-icons/icon-earning-dark.svg";
import iconLiveFlowPrimary from "../../assets/images/menu-icons/icon-bar-graph-primary.svg";
import iconTickerPrimary from "../../assets/images/menu-icons/icon-ticket-primary.svg";
import iconBookmarkPrimary from "../../assets/images/menu-icons/icon-start-dark.svg";
import iconFilterPrimary from "../../assets/images/menu-icons/icon-filter-sliders-primary.svg";
import iconAlertPrimary from "../../assets/images/menu-icons/icon-bell-primary.svg";
import iconSettingPrimary from "../../assets/images/menu-icons/icon-cog-primary.svg";
import iconLogoutPrimary from "../../assets/images/menu-icons/icon-logout-primary.svg";
import iconDarkPoolPrimary from "../../assets/images/menu-icons/icon-trend-tag-primary.svg";

interface TooltipInterface {
  id: string;
  content: string;
  place: 'right' | 'bottom';
}
interface SidebarSection {
  title: string;
  icon: string;
  link: string;
  tooltip: TooltipInterface,
  items?: {
    title: string;
    icon: string;
    link: string;
    tooltip: TooltipInterface
  }[];
}


const Sidebar = ({ theme, setTheme }: any) => {
  const location = useLocation()
  const [expandedMenu, setExpandedMenu] = useState<boolean>(false);
  const [expandedSections, setExpandedSections] = useState<Record<string, boolean>>({});
  const [currentMenu, setCurrentMenu] = useState<string>('');

  const navigate = useNavigate()

  useEffect(() => {
    setCurrentMenu(location.pathname)
  }, [location.pathname])
  
  const toggleSection = (title: string) => {
    setExpandedSections((prev) => ({
      ...prev,
      [title]: !prev[title],
    }));
  };
  const navigateTo = (title: string) => {
    setCurrentMenu(title)
    navigate(title);
  };


  const sidebarData: SidebarSection[] = [
    {
      title: "Dashboard",
      icon: (
        theme == "light" ? iconDashboardPrimary : iconDashboardLight
      ),
      link: '/dashboard',
      tooltip: {
        id: 'menu-dashboard',
        content: 'Dashboard',
        place: 'right',
      }
    },
    {
      title: "Heatmap",
      icon: (
        theme == "light" ? iconDarkPoolPrimary : iconDarkPoolLight
      ),
      link: '/heatmap',
      tooltip: {
        id: 'menu-heatmap',
        content: 'Heatmap',
        place: 'right',
      }
    },
    {
      title: "Top Gainer/Losers",
      icon: (
        theme == "light" ? iconFilterPrimary : iconFilterLight
      ),
      link: '/top-gainer-loser',
      tooltip: {
        id: 'menu-gainerlooser',
        content: 'Top Gainer/Losers',
        place: 'right',
      }
    },
    {
      title: "Hottest Contracts",
      icon: (
        theme == "light" ? iconHotFirePrimary : iconHotFireLight
      ),
      link: '/hottest-contracts',
      tooltip: {
        id: 'menu-hottest-contracts',
        content: 'Hottest Contracts/Chains',
        place: 'right',
      }
    },
    {
      title: "Unusual Flow Picks",
      icon: (
        theme == "light" ? iconLiveFlowPrimary : iconLiveFlowLight
      ),
      link: '/unusual-flow-picks',
      tooltip: {
        id: 'menu-unusual-flow-picks',
        content: 'Unusual Flow Picks',
        place: 'right',
      }
    },
    {
      title: "Tickers",
      icon: (
        theme == "light" ? iconTickerPrimary : iconTickerLight
      ),
      link: '/tickers',
      tooltip: {
        id: 'menu-tickers',
        content: 'Tickers',
        place: 'right',
      }
    },
    {
      title: "Bookmarks",
      icon: (
        theme == "light" ? iconBookmarkPrimary : iconBookmarkLight
      ),
      link: '/bookmark',
      tooltip: {
        id: 'menu-bookmark',
        content: 'Bookmarks',
        place: 'right',
      }
    },
    {
      title: "News",
      icon: (
        theme == "light" ? iconNewsPrimary : iconNewsLight
      ),
      link: '/news',
      tooltip: {
        id: 'menu-news',
        content: 'News',
        place: 'right',
      }
    },
    {
      title: "Earning Calendar",
      icon: (
        theme == "light" ? iconEarningDark : iconEarningLight
      ),
      link: '/earning-calendar',
      tooltip: {
        id: 'earning-calendar',
        content: 'Earning Calendar',
        place: 'right',
      },
    },
    // {
    //   title: "Senate Holdings",
    //   icon: (
    //     theme == "light" ? iconSenateDark : iconSenateLight
    //   ),
    //   link: '/senate-holdings',
    //   tooltip: {
    //     id: 'menu-senate-holdings',
    //     content: 'Senate Holdings',
    //     place: 'right',
    //   }
    // },
    // {
    //   title: "Calendars",
    //   icon: (
    //     theme == "light" ? iconCalendarPrimary : iconCalendarLight
    //   ),
    //   link: '/',
    //   tooltip: {
    //     id: 'menu-calendar',
    //     content: 'calendar',
    //     place: 'right',
    //   },
    //   items: [
    //     {
    //       title: "Earning",
    //       icon: (
    //         theme == "light" ? iconEarningDark : iconEarningLight
    //       ),
    //       link: '/earning-calendar',
    //       tooltip: {
    //         id: 'earning-calendar',
    //         content: 'Earning Calendar',
    //         place: 'right',
    //       },
    //     },
    //     {
    //       title: "Dividend",
    //       icon: (
    //         theme == "light" ? iconDividendDark : iconDividendLight
    //       ),
    //       link: '/dividend-calendar',
    //       tooltip: {
    //         id: 'dividend-calendar',
    //         content: 'Dividend Calendar',
    //         place: 'right',
    //       },
    //     },
    //   ],
    // },
  ];

  return (
    <aside
      className={`app-aside d-flex flex-column justify-content-between ${expandedMenu ? "menu-expanded" : ""
        }`}
    >

      <div className="navigation-container d-flex flex-column">
        {sidebarData.map((section) => (
          <div key={section.title} className="nav-item mt-3">
            <div
              className={`nav-link d-flex px-3 py-3 align-items-center cursor-pointer ${section.link === '/dashboard' ? "skip-active" : "" } ${currentMenu === section.link ? "active" : "" }`}
              data-tooltip-id={section.tooltip.id || ''}
              data-tooltip-content={section.tooltip.content || ''}
              data-tooltip-place={section.tooltip.place || ''}
              onClick={() => (section.items && section.items?.length > 0) ? toggleSection(section.title) : navigateTo(section.link)}
            >
              <div className="d-flex">
                <img
                  src={section.icon}
                  alt=""
                  
                />
                <Tooltip className="nav-tooltip" content={section.tooltip.content} id={section.tooltip.id} />
                <h2 className="ms-2">{section.title}</h2>
              </div>
              <div className="side-right">
                {
                  (section?.items && section?.items?.length > 0) && (expandedSections[section.title] 
                    ? <FontAwesomeIcon icon={icon({ name: "chevron-up" })} />
                    : <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />)
                }
              </div>
             
            </div>
            {
              expandedSections[section.title] && (
                <div className="ms-4 border-l border-gray-600 d-flex flex-column expanded-section">
                  {section?.items && section?.items?.map((item) => (
                    <div className="nav-link d-flex px-3 py-3 align-items-center cursor-pointer"
                      onClick={() => navigateTo(item.link)}
                    >
                      <img
                        src={item.icon}
                        alt=""

                      />
                      <Tooltip className="nav-tooltip" content={item.tooltip.content} id={item.tooltip.id} />
                      <h2 className="ms-2">{item.title}</h2>
                    </div>
                  ))}
                </div>
              )
            }
          </div>
        ))}
      </div>
      <button
        type="button"
        onClick={() => {
          setExpandedMenu(!expandedMenu);
        }}
        className={`btn btn-icon h-20px w-20px btn-sm rounded-circle aside-toggle ${theme == "light" ? "btn-primary" : "bg-white text-primary"
          }`}
      >
        <span
          data-tooltip-id="menu-togglemenu"
          data-tooltip-content={expandedMenu ? "Collapse" : "Expand"}
          data-tooltip-place="right"
        >
          {expandedMenu ? (
            <FontAwesomeIcon icon={icon({ name: "chevron-left" })} />
          ) : (
            <FontAwesomeIcon icon={icon({ name: "chevron-right" })} />
          )}
        </span>
        <Tooltip className="nav-tooltip fs-7" id="menu-togglemenu" />
      </button>
      <nav className="side-nav side-nav-secondary mt-auto">
        <div className="nav d-flex flex-column align-items-center">
          <div className="theme-toggle">
            <div className="form-check form-switch form-check-custom form-check-solid">
              <input
                type="checkbox"
                className="form-check-input"
                id="themeToggle"
                role="switch"
                checked={theme == "dark" ? true : false}
                onChange={() => { }}
                onClick={() => {
                  setTheme(theme == "dark" ? "light" : "dark");
                }}
              />
              <label htmlFor="themeToggle" className="form-check-label">
                {theme == "dark"
                  ? "Switch to light mode"
                  : "Switch to dark mode"}
              </label>
              <Tooltip className="nav-tooltip" id="menu-toggletheme" />
            </div>
          </div>
        </div>
      </nav>
    </aside>
  );
};

export default Sidebar;